import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";
import moment from "moment/moment";
import i18n from "@/lang";
import {interceptorAxios} from "@/api/http/interceptorAxios";

export function fetchEvent(eventId) {
  return axios.get(baseUrl + baseApiVersion + `/event/${eventId}`, {
    headers,
  });
}

export function createEvent(data) {
  return axios.post(baseUrl + baseApiVersion + `/event`, data, {
    headers,
  });
}

export function updateEvent(eventId, data) {
  return axios.patch(baseUrl + baseApiVersion + `/event/${eventId}`, data, {
    headers,
  });
}

export function updateEventStatus() {
  return axios.patch(baseUrl + baseApiVersion + `/event/changeStatus`, {
    headers,
  });
}

export function closeEventStatus(data) {
  return axios.patch(baseUrl + baseApiVersion + `/event/changeStatus`, data, {
    headers,
  });
}


export function deleteEvent(eventId) {
  return axios.delete(baseUrl + baseApiVersion + `/event/${eventId}`, {
    headers,
  });
}

export function updateMemo(eventId, data) {
  return axios.patch(baseUrl + baseApiVersion + `/event/${eventId}/memo`, data, {
    headers,
  });
}

export function fetchEventList(params) {
  return interceptorAxios.get('/event/list',{params: params});
  // return axios.get(baseUrl + baseApiVersion + `/event/list`, { params, headers });
}

export function fetchNoticeList(params) {
  return axios.get(baseUrl + baseApiVersion + `/event/notice/list`, { params, headers });
}

export function fetchEventNoticeList(params) {
  return axios.get(baseUrl + baseApiVersion + `/event/notice/list`, {
    params,
    headers,
  });
}

export function getNoticeImageUrl(accountId, eventId, index) {
  return baseUrl + baseApiVersion + `/event/notice/account/${accountId}/eventId/${eventId}/index/${index}/image`;
}

export function createNotice(eventId, data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/event/notice/${eventId}`, data, {
    headers,
  });
}

export function deleteNotice(index, eventId) {
  return axios.delete(baseUrl + baseApiVersion + `/event/notice/${index}/event/${eventId}`, {
    headers,
  });
}

export function fetchEventWeather(eventId) {
  return axios.get(baseUrl + baseApiVersion + `/weather/event/${eventId}`, { headers });
}

export function fetchNoticeAppList(params) {
  return axios.get(baseUrl + baseApiVersion + `/event/noticeApp/list`, { params, headers });
}

export function createNoticeApp(data){
  return axios.post(baseUrl + baseApiVersion + `/event/noticeApp`, data,{ headers });
}

export function createNoticeAppFileUpload(data){
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/event/noticeApp/files`, data, {
    headers,
  });
}

export function fetchNoticeAppInfo(noticeAppId){
  return axios.get(baseUrl + baseApiVersion + `/event/noticeApp/${noticeAppId}`, { headers });
}


export function fetchNoticeAppFileListInfo(noticeAppId){
  return axios.get(baseUrl + baseApiVersion + `/event/noticeApp/list/files/${noticeAppId}`, { headers });
}

export function getNoticeAppFilePath(fileId){
  return baseUrl + baseApiVersion + `/event/noticeApp/download/${fileId}`;
}

export function updateNoticeAppInfo(noticeAppId, data){
  return axios.patch(baseUrl + baseApiVersion + `/event/noticeApp/${noticeAppId}`, data, {
    headers,
  });
}

export function deleteNoticeApp(noticeAppId){
  return axios.delete(baseUrl + baseApiVersion + `/event/noticeApp/${noticeAppId}`, {
    headers,
  });
}

export function fetchEventLogList(params) {
  return axios.get(baseUrl + baseApiVersion + `/eventLog/list`, { params, headers });
}

export function changeEventLogData(newList, oldList) {
  if(newList.length == 0) {
    return null;
  }

  newList.sort((item)=> new Date(item.logDate).getTime());
  var resultData = null;
  if(oldList == undefined || oldList == null || oldList.length == 0) {
    resultData = newList;
  }
  let newLastData = newList[newList.length - 1];
  let oldLastData = null;
  if(oldList != null && oldList != undefined && oldList.length > 0) {
    oldList.sort((item)=> new Date(item.logDate).getTime());
    oldLastData = oldList[oldList.length - 1];
  }

  if(oldLastData != null && newLastData.id.index != oldLastData.id.index) {
    resultData = newList;
  }

  if(resultData != null) {
    resultData.forEach((item) => {
      item.showDate = moment(item.logDate).format("HH:mm");
      item.showTitle = item.title;
      let message = "";
      let result = "";
      switch (item.tag) {
        case "Drone" :
          if(item.action == 'DeviceStart') {
            result = i18n.t("notice-event-flight-start");
          }
          if(item.action == 'DeviceEnd') {
            result = i18n.t("notice-event-flight-end");
          }
          if(result != "") {
            message = item.message.deviceName + result;
          }
          break;
        case "User" :
          if(item.action == 'Enter') {
            result = i18n.t("notice-event-user-enter");
          }
          if(item.action == 'Leave') {
            result = i18n.t("notice-event-user-leave");
          }
          if(result != "") {
            message = item.message.name + result;
          }
          break;
        case "Point" :
          if(item.action == 'PointCreate') {
            result = i18n.t("notice-event-point-create");
          }
          if(item.action == 'PointDelete') {
            result = i18n.t("notice-event-point-del");
          }
          if(item.action == 'PointModify') {
            result = i18n.t("notice-event-point-update");
          }
          if(result != "") {
            message = item.message.userName + result;
          }
          break;
        case "Event" :
          if(item.action == 'Open') {
            result = i18n.t("notice-event-open");
          }
          if(item.action == 'Close') {
            result = i18n.t("notice-event-close");
          }
          if(item.action == 'Create') {
            result = i18n.t("notice-event-create");
          }
          if(result != "") {
            message = item.message.title + result;
          }
          break;
        default :
          message = "";
      }
      item.showMessage = message;
    });
  }
  return resultData;
}

export function postCheckDepartment(eventId){
  return axios.post(baseUrl + baseApiVersion + `/event/${eventId}/checkDepartment`,null,{ headers });

}